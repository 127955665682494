import React, { Component } from 'react';

class Contact extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
    }

    return (
      <section id="contact">

         <div className="row section-head">



            <div>
               <h1>Get in touch!</h1>
            </div>

         </div>

         <div className="row">


            <aside className="four footer-widgets">
               <div className="widget widget_contact">

					   <h4>Email and Phone</h4>
					   <p className="address">
						   {name}<br />
						   {email} <br />
						   {phone}<br />
						   <span>{city}, {state}</span>
					   </p>
				   </div>
            </aside>
      </div>
   </section>
    );
  }
}

export default Contact;
